import React, { useContext, useEffect, useMemo, useState } from "react";
import clsx from "clsx";
import { makeStyles, Divider, Hidden } from "@material-ui/core";
import ReportAttributesPanel from "./ReportAttributesPanel";
import ChatPlaceholder from "./ChatPlaceholder";
import ReportChat from "./ReportChat";
import ReportToolbar from "./ReportToolbar";
import { useDispatch, useSelector } from "react-redux";
import { workspaceReports } from "../../state/reports/selectors";
import { useTranslation } from "react-i18next";
import InfoIcon from "@material-ui/icons/Info";
import GroupIcon from "@material-ui/icons/Group";
import BallotIcon from "@material-ui/icons/Ballot";
import { CardShadow, CardShadow2 } from "../../constants";
import { useReport } from "../../hooks/useReport";
import { Navigate, useNavigate, useParams } from "react-router-dom";
import { toId, toReportKey, toWorkspaceKey } from "../../utils/apiUtils";
import ReportList from "./ReportList";
import { isApprover } from "../../utils/user";
import { currentUserRoleSelector } from "../../state/members/selectors";
import ReportWithChat from "./ReportWithChat";
import ReportWithoutChat from "./ReportWithoutChat";
import Page from "../../components/Page/Page";
import { workspaceSelector } from "../../state/workspaces/selectors";
import WorkspacePage from "../../components/WorkspacePage/WorkspacePage";

const useStyles = makeStyles((theme) => ({
    reportsView: {
        display: "flex",
        // height: "100%"
        //height: `calc(100% - ${TopBarHeight}px)`,
    },
    placeholder: {
        flex: "3 3 auto",
        minWidth: 0,
    },
    reportsListContainer: {
        flex: "0 0 auto",
        //display: "flex",
        //flexDirection: "column",
        [theme.breakpoints.down("sm")]: {
            width: "100%",
            display: (props) => (props.reportSelected ? "none" : "initial"),
        },
        [theme.breakpoints.up("md")]: {
            width: theme.spacing(45),
        },
        [theme.breakpoints.up("xl")]: {
            width: theme.spacing(52),
        },
        //backgroundColor: theme.palette.background.paper
    },
    reportsList: {
        height: "100%",
        //minHeight: 0,
    },
    content: {
        flex: "1 1 auto",
    },
    reportContent: {
        flex: "1 1 auto",
        minWidth: 0,
    },
}));

const statusFilters = ["all", "open", "solved", "junk"];

// TODO: Approver should not get report messages

// TODO: Rename to ReportsView
// TODO: Fix amount of cards rerenderings
// TODO: Reduce flex boxes
const ReportsView = () => {
    // TODO: Merge active report with reportOpen?
    const { t } = useTranslation();
    const navigate = useNavigate();
    const { workspaceId, reportId } = useParams();
    const workspaceKey = toWorkspaceKey(workspaceId);
    const reportKey = reportId ? toReportKey(reportId) : null;
    useReport(reportKey, workspaceKey);
    const reports = useSelector(workspaceReports(workspaceKey));
    const selectedReport = reportKey ? reports?.find((report) => report.pk === reportKey) : null;
    const classes = useStyles({ reportSelected: Boolean(reportId) });
    const currentUserRole = useSelector(currentUserRoleSelector(workspaceKey));

    useEffect(() => {
        if (reportId && !selectedReport) {
            // Navigate to /reports if user tried to access not existing report by url
            navigate(`/portal/${workspaceId}/reports`);
        }
        // else if (!reportId && reports && reports.length > 0) {
        //     // Navigate to first available report if no report was specified in url
        //     navigate(`./${toId(reports[0].pk)}`);
        // }
    }, [reportId]);

    const handleReportSelected = (key) => {
        console.log("navigating to report %o", key);
        // TODO: Move urls to constants
        navigate(`/portal/${workspaceId}/reports/${toId(key)}`);
    };

    return (
        <WorkspacePage className={classes.reportsView} disableScroll>
            <div className={classes.reportsListContainer}>
                <ReportList
                    className={classes.reportsList}
                    reports={reports}
                    selectedKey={reportKey}
                    statusFilter={statusFilters[0]}
                    onReportSelected={handleReportSelected}
                />
            </div>
            <Divider orientation="vertical" />
            <Hidden smDown>{!selectedReport && <ChatPlaceholder className={classes.placeholder} />}</Hidden>
            {selectedReport &&
                (isApprover(currentUserRole) ? (
                    <ReportWithoutChat className={classes.reportContent} report={selectedReport} />
                ) : (
                    <ReportWithChat className={classes.reportContent} report={selectedReport} />
                ))}
        </WorkspacePage>
    );
};

export default ReportsView;
