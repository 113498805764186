import React, {useContext, useEffect, useMemo, useRef, useState} from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import {
    Avatar,
    Box,
    Card,
    CardContent,
    Grid,
    Typography,
    colors,
    makeStyles,
    CssBaseline,
    Table,
    TableHead,
    TableRow,
    TableCell,
    Checkbox,
    TableBody,
    TablePagination,
    List,
    ListItem,
    ListItemAvatar, useTheme, ListItemText, Divider, ListItemSecondaryAction, ListItemIcon, ButtonBase, TextField
} from '@material-ui/core';
import {useTranslation} from "react-i18next";
import {TopBarHeight} from "../../constants";
import ChatInputBox from "./ChatInputBox";
import {scrollToView} from "../../utils/ui";
import ChatMessageSkeleton from "./ChatMessageSkeleton";
import {isSystemMessage} from "../../utils/reportUtils";
import SystemMessage from "./SystemMessage";
import ChatMessage from "./ChatMessage";
import {useSelector} from "react-redux";
import {allMembersSelector} from "../../state/members/selectors";
import {currentUserSelector} from "../../state/currentUser/selectors";
import {toUserKey} from "../../utils/apiUtils";
import {getMessageSender} from "../../utils/redux";
import {useFetchMissingSenders} from "../../hooks/useFetchMissingSenders";

const InputBoxHeight = 90;

const useStyles = makeStyles((theme) => ({
    chat: {
        //height: "100%",
        display: "flex",
        flexDirection: "column"
    },
    content: {
        //height: `calc(100% - ${InputBoxHeight}px)`
        flex: "1 1 auto",
        minHeight: 0,
        overflow: "auto"
    },
    messageContainer: {
        padding: [theme.spacing(4), theme.spacing(4), theme.spacing(3), theme.spacing(4)],
        '&>div:not(:last-child) ': {
            marginBottom: theme.spacing(3),
        }
    },
    inputContainer: {
        flex: "0 0 auto",
        padding: theme.spacing(1),
        margin: [0, theme.spacing(3), theme.spacing(3), theme.spacing(3)],
        backgroundColor: theme.palette.background.paper
    },
    disabledInput: {
        transition: `opacity 75ms ease-in-out`,
        opacity: "0.5",
        pointerEvents: "none",
        backgroundColor: theme.palette.grey[100]
    },
}));

const renderMessage = (message, users, currentUser, onRendered) => {
    const sender = getMessageSender(message, users);

    let position = "left";
    if ((!Boolean(currentUser) && !message.sender) ||
        (Boolean(currentUser) && message.sender && message.sender === currentUser.pk)) {
        position = "right";
    }

    if (Boolean(message.sender) && !sender) {
        // In case the sender is not yet fetched return message skeleton
        return (<ChatMessageSkeleton key={message.createdAt} position={position}/>)
    }

    return isSystemMessage(message) ? (
        <SystemMessage
            key={message.sk}
            message={message}
            sender={sender}
            onRendered={onRendered}
        />
    ) : (
        <ChatMessage
            key={message.sk}
            message={message}
            sender={sender}
            position={position}
            onRendered={onRendered}
        />
    )
}

const Chat = ({className, classes: propClasses, workspaceKey, header, messages, inputDisabled, inputPlaceholder, onSubmit}) => {
    const classes = useStyles();
    const { t } = useTranslation();
    const messagesEndRef = useRef(null);
    const users = useSelector(allMembersSelector(workspaceKey));
    const currentUser = useSelector(currentUserSelector());
    useFetchMissingSenders(workspaceKey, messages);

    const handleMessageRendered = () => {
        scrollToView(messagesEndRef);
    }

    return (
        <div className={clsx(classes.chat, className)}>
            <div className={classes.content}>
                <div className={clsx(classes.messageContainer, propClasses.messageContainer)}>
                    { header && header }
                    { messages.map(msg => renderMessage(msg, users, currentUser, handleMessageRendered)) }
                </div>
                <div ref={messagesEndRef} />
            </div>
            <div className={classes.inputContainer}>
                <ChatInputBox
                    className={clsx({[classes.disabledInput]: inputDisabled})}
                    onSubmit={onSubmit}
                    buttonType="standard"
                    placeholder={inputPlaceholder}
                />
            </div>
        </div>
    );
}

Chat.propTypes = {
    className: PropTypes.string,
    classes: PropTypes.object,
    onSubmit: PropTypes.func,
    workspaceKey: PropTypes.string,
    messages: PropTypes.arrayOf(PropTypes.shape({
        pk: PropTypes.string.isRequired,
        sk: PropTypes.string.isRequired,
        createdAt: PropTypes.string.isRequired,
        sender: PropTypes.string
    })),
    header: PropTypes.element,
    inputDisabled: PropTypes.bool,
    inputPlaceholder: PropTypes.string
}

Chat.defaultProps = {
    classes: {}
};

export default Chat;