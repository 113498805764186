import React, {useContext, useEffect, useRef, useState} from 'react';
import {
    makeStyles,
    Divider,
    ThemeProvider,
    Hidden,
    Drawer,
    Dialog,
    DialogContent,
    useMediaQuery, useTheme, Typography
} from "@material-ui/core"
import {Navigate, NavLink, Outlet, useNavigate, useParams} from 'react-router-dom';
import clsx from 'clsx';
import NavBar from "./NavBar";
import {useDispatch, useSelector} from "react-redux";
import {allWorkspacesSelector, workspaceSelector} from "../../state/workspaces/selectors";
import {useWorkspace} from "../../hooks/useWorkspace";
import {toId, toWorkspaceKey} from "../../utils/apiUtils";
import {useFetchWorkspaces} from "../../hooks/useFetchWorkspaces";
import Loader from "../../components/Loader";
import NotificationOptInDialog from "../../components/NotificationOptInDialog";
import {useTranslation} from "react-i18next";
import {TopBarHeight} from "../../constants";
import {currentUserSelector} from "../../state/currentUser/selectors";
import {currentUserMemberships} from "../../state/members/selectors";
import PortalTopBar from "./PortalTopBar";

const useStyles = makeStyles((theme) => ({
    layoutRoot: {
        overflow: "hidden",
        height: '100%',
        display: "flex"
    },
    errorContainer: {
        height: '100%',
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center"
    },
    container: {
        height: "100%",
        flex: "1 1 auto"
    },
    main: {
        height: `calc(100% - ${TopBarHeight + 1}px)`, // + 1 is to include divider under top bar,
    }
}));

const PortalLayout = () => {
    const classes = useStyles();
    const { t } = useTranslation();
    const theme = useTheme();
    const smallScreen = useMediaQuery(theme.breakpoints.down('sm'));
    const workspaceKeys = useSelector(currentUserMemberships()) ?? [];
    const workspacesLoaded = useFetchWorkspaces(workspaceKeys);

    if (smallScreen) {
        return (
            <div className={classes.errorContainer}>
                <Typography variant="h5" color="textSecondary">
                    {t("screenSizeNotSupported")}
                </Typography>
            </div>
        )
    }

    return (
        <div className={classes.layoutRoot}>
            <NavBar showSkeletons={!workspacesLoaded} skeletonCount={workspaceKeys.length}/>
            {workspacesLoaded ? (
                <div className={classes.container}>
                    <PortalTopBar/>
                    <main className={classes.main}>
                        <Outlet/>
                    </main>
                </div>
            ) : (
                <Loader/>
            )}
        </div>
    )
}

export default PortalLayout;