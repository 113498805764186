import React, {useContext, useEffect, useRef, useState} from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import {
    Avatar, CardActions, CardContent, CardHeader, CardMedia,
    Chip, Grid,
    makeStyles, Popover,
    TextField, Typography,
} from '@material-ui/core';
import MUIRichTextEditor from 'mui-rte'
import {useTranslation} from "react-i18next";
import Button from "@material-ui/core/Button";
import VpnKeyIcon from "@material-ui/icons/VpnKey";
import TitleIcon from '@material-ui/icons/Title';
import { EditorState } from 'draft-js'
import Card from "../Card";
import IconButton from "@material-ui/core/IconButton";
import StarIcon from '@material-ui/icons/Star';
import ShareIcon from '@material-ui/icons/Share';
import CloseIcon from '@material-ui/icons/Close';
import DoneIcon from '@material-ui/icons/Done';
import WebAssetIcon from '@material-ui/icons/WebAsset';

const cardPopverStyles = makeStyles({
    root: {
        padding: 10,
        maxWidth: 350
    },
    textField: {
        width: "100%"
    }
})

const cardStyles = makeStyles({
    root: {
        maxWidth: 345
    },
    media: {
        height: 0,
        paddingTop: '56.25%'
    },
    avatar: {
        backgroundColor: "tomato"
    }
})

const save = (data) => {
    console.log(data);
};

const MyCard = (props) => {
    const { blockProps } = props
    const classes = cardStyles(props)

    const handleLiked = () => {
        alert("Favorited")
    }

    const handleShared = () => {
        alert("Shared")
    }

    return (
        <Card className={classes.root}>
            <CardHeader
                avatar={
                    <Avatar aria-label="name" className={classes.avatar}>
                        {blockProps.name && blockProps.name.substring(0, 1)}
                    </Avatar>
                }
                title={blockProps.title}
                subheader={blockProps.date && blockProps.date.toLocaleDateString()}
            />
            <CardMedia
                className={classes.media}
                image={blockProps.image || "default"}
                title={blockProps.title}
            />
            <CardContent>
                <Typography variant="body2" color="textSecondary" component="p">
                    {blockProps.text}
                </Typography>
            </CardContent>
            <CardActions disableSpacing>
                <IconButton
                    aria-label="like card"
                    onClick={handleLiked}>
                    <StarIcon />
                </IconButton>
                <IconButton
                    aria-label="share"
                    onClick={handleShared}
                >
                    <ShareIcon />
                </IconButton>
            </CardActions>
        </Card>
    )
}

const MyCardPopover = (props) => {
    const classes = cardPopverStyles(props)
    const [state, setState] = useState({
        anchor: null,
        isCancelled: false
    })
    const [data, setData] = useState({})

    useEffect(() => {
        setState({
            anchor: props.anchor,
            isCancelled: false
        })
        setData({
            date: new Date()
        })
    }, [props.anchor])

    const handleChange = (event) => {
        setData({
            ...data,
            [event.target.name]: event.target.value
        })
    }

    const textFieldProps = {
        className: classes.textField,
        onChange: handleChange,
        InputLabelProps: {
            shrink: true
        }
    }

    return (
        <Popover
            anchorEl={state.anchor}
            open={state.anchor !== null}
            anchorOrigin={{
                vertical: "bottom",
                horizontal: "right"
            }}
            transformOrigin={{
                vertical: 'top',
                horizontal: 'left',
            }}
        >
            <Grid container spacing={1} className={classes.root}>
                <Grid item xs={6}>
                    <TextField
                        {...textFieldProps}
                        autoFocus={true}
                        label="Title"
                        name="title"
                    />
                </Grid>
                <Grid item xs={6}>
                    <TextField
                        {...textFieldProps}
                        label="common.name"
                        name="name"
                    />
                </Grid>
                <Grid item xs={12}>
                    <TextField
                        {...textFieldProps}
                        label="Text"
                        name="text"
                    />
                </Grid>
                <Grid item xs={12}>
                    <TextField
                        {...textFieldProps}
                        label="Image URL"
                        name="image"
                    />
                </Grid>
                <Grid item container xs={12} justifyContent="flex-end">
                    <Button onClick={() => {
                        setState({
                            anchor: null,
                            isCancelled: true
                        })
                    }}
                    >
                        <CloseIcon />
                    </Button>
                    <Button onClick={() => {
                        setState({
                            anchor: null,
                            isCancelled: false
                        })
                        props.onSubmit(data, !state.isCancelled)
                    }}
                    >
                        <DoneIcon />
                    </Button>
                </Grid>
            </Grid>
        </Popover>
    )
}

const RichText2 = ({className, canBeEmpty, TextFieldProps, LabelProps, onChange, onApplied}) => {
    const ref = useRef(null)
    const [anchor, setAnchor] = useState(null);

    return (
        <>
            <MyCardPopover
                anchor={anchor}
                onSubmit={(data, insert) => {
                    if (insert && ref.current) {
                        ref.current.insertAtomicBlockSync("my-card", data)
                    }
                    setAnchor(null)
                }}
            />
            <MUIRichTextEditor
                label="Press the last icon in the toolbar to insert an atomic custom block...."
                ref={ref}
                onSave={save}
                controls={["title", "bold", "underline", "save", "add-card"]}
                customControls={[
                    {
                        name: "my-card",
                        type: "atomic",
                        atomicComponent: MyCard
                    },
                    {
                        name: "add-card",
                        icon: <WebAssetIcon />,
                        type: "callback",
                        onClick: (_editorState, _name, anchor) => {
                            setAnchor(anchor)
                        }
                    }
                ]}
            />
        </>
    )
}

RichText2.propTypes = {
    className: PropTypes.string,
}

RichText2.defaultProps = {
    initialValue: "",
    LabelProps: {},
    orientation: "row",
    canBeEmpty: true,
    TextFieldProps: {},
    onChange: () => {},
    onApplied: () => {}
};

export default RichText2;