import React, {useContext, useEffect, useState} from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import {
    Avatar,
    Box,
    Card,
    CardContent,
    Grid,
    Typography,
    colors,
    makeStyles,
    CssBaseline,
    Table,
    TableHead,
    TableRow,
    TableCell,
    Checkbox,
    TableBody,
    TablePagination,
    List,
    ListItem,
    ListItemAvatar,
    useTheme,
    ListItemText,
    Divider,
    ListItemSecondaryAction,
    ListItemIcon,
    ButtonBase,
    Tooltip,
    Toolbar,
    TextField, Container, Fab, Button, MenuItem
} from '@material-ui/core';
import IconButton from "@material-ui/core/IconButton";
import MoreVertOutlinedIcon from "@material-ui/icons/MoreVertOutlined";
import MicNoneOutlinedIcon from '@material-ui/icons/MicNoneOutlined';
import AttachFileOutlinedIcon from '@material-ui/icons/AttachFileOutlined';
import SendIcon from '@material-ui/icons/Send';
import AttachmentPreview from "../attachments/AttachmentPreview";
import AttachmentsContainer from "../AttachmentsContainer";
import AttachmentUploadProgress from "../attachments/AttachmentUploadProgress";
import {ApiContext} from "../../contexts/ApiContext";
import {numericId} from "../../utils/idGenerator";
import {useTranslation} from "react-i18next";
import AddAttachmentButton from "../attachments/AddAttachmentButton";
import {LocalAttachment} from "../attachments";
import {getFileKey} from "../../utils/fileUtils";

const useStyles = makeStyles((theme) => ({
    chatInputBox: {
        display: "flex",
        flexDirection: "row",
        alignItems: "flex-start",
        borderRadius: 6,
        border: `1px solid ${theme.palette.border.default}`,
        backgroundColor: theme.palette.background.paper
        // '&:hover': {
        //     borderColor: theme.palette.border.hover
        // }
    },
    button: {
        flex: "0 0 auto",
        marginBottom: 0
    },
    inputContainer: {
        flex: "1 1 auto",
        minWidth: 0,
        // marginTop: props => props.layout === "row" ? theme.spacing(1) : 0,
        // marginRight: props => props.layout === "row" ? theme.spacing(1.5) : 0,
        // marginLeft: props => props.layout === "row" ? theme.spacing(1.5) : 0,
        //marginBottom: theme.spacing(1)
        margin: [theme.spacing(1), theme.spacing(1.5)]
    },
    attachmentsContainer: {
        marginTop: theme.spacing(0.5)
    },
    standardSubmit: {
        marginTop: theme.spacing(0.75),
        marginRight: theme.spacing(0.75),
    }
}));

const ChatInputBox = ({className, buttonType, placeholder, allowAttachments, onSubmit}) => {
    const classes = useStyles();
    const { t } = useTranslation();
    const [content, setContent] = useState("");
    const [attachments, setAttachments] = useState([]);

    const handleSubmit = (event) => {
        event.persist()
        event.preventDefault();
        onSubmit({content, attachments: attachments.filter(a => Boolean(a.key)).map(a => a.key)});
        setContent("");
        setAttachments([]);
    };

    const handleFileAdded = (file) => {
        const attachmentId = getFileKey(file);
        const isAttached = attachments.some(attachment => attachment.id === attachmentId);
        if (!isAttached) {
            setAttachments(prev => {
                const attachment = { file: file, id: attachmentId };
                return [...prev, attachment];
            });
        }
    }

    const handleAttachmentUploaded = (attachmentId, key) => {
        setAttachments(prev => {
            const index = prev.findIndex(attachment => attachment.id === attachmentId);
            const newState = [...prev];
            newState[index].key = key;
            return newState;
        });
    }

    const handleAttachmentDeleted = (attachmentId) => {
        setAttachments(prev => {
            const index = prev.findIndex(attachment => attachment.id === attachmentId);
            const newState = [...prev];
            newState.splice(index, 1);
            return newState;
        });
    }

    return (
        <form
            className={clsx(classes.chatInputBox, className)}
            autoComplete="off"
            onSubmit={handleSubmit}
        >
            {allowAttachments && (
                <AddAttachmentButton
                    className={classes.button}
                    variant='icon'
                    component="span"
                    onFileAdded={handleFileAdded}
                >
                    <AttachFileOutlinedIcon />
                </AddAttachmentButton>
            )}
            <div className={classes.inputContainer}>
                <TextField
                    value={content}
                    placeholder={placeholder}
                    required
                    fullWidth
                    multiline
                    maxRows="10"
                    name="message"
                    hiddenLabel
                    onChange={(e) => setContent(e.target.value)}
                    InputProps={{
                        disableUnderline: true
                    }}
                />
                { (attachments.length > 0) && (
                    <AttachmentsContainer className={classes.attachmentsContainer}>
                        {attachments.map((attachment) => (
                            <LocalAttachment
                                key={attachment.id}
                                file={attachment.file}
                                onUploaded={(key) => handleAttachmentUploaded(attachment.id, key)}
                                onDeleted={() => handleAttachmentDeleted(attachment.id)}
                            />
                        ))}
                    </AttachmentsContainer>
                )}
            </div>
            {buttonType === "standard" ? (
                <Button
                    className={clsx(classes.button, classes.standardSubmit)}
                    type="submit"
                    size="medium"
                    color="primary"
                    variant="contained"
                >
                    {t("send")}
                </Button>
            ) : (
                <Tooltip title={t("send")}>
                    <IconButton className={classes.button} size="medium" aria-label="send" type="submit">
                        <SendIcon />
                    </IconButton>
                </Tooltip>
            )}
        </form>
    );
}

ChatInputBox.propTypes = {
    className: PropTypes.string,
    allowAttachments: PropTypes.bool,
    placeholder: PropTypes.string,
    buttonType: PropTypes.oneOf(["icon", "standard"])
}

ChatInputBox.defaultProps = {
    allowAttachments: true,
    buttonType: "standard",
};

export default ChatInputBox;