/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const listReportMessages = /* GraphQL */ `
  query ListReportMessages(
    $pk: ID!
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listReportMessages(
      pk: $pk
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        pk
        sk
        workspace
        owners
        group
        readOnlyGroup
        subscription
        createdAt
        updatedAt
        name
        picture
        email
        plan
        address
        phone
        cin
        country
        locale
        role
        description
        solution
        state
        stateUpdatedAt
        excerpt
        excerptUpdatedAt
        sender
        text
        content
        attachments
        submitLayout
        disabledSubmitLanguages
        reportSettings
        lastReads
        pushSubscription
        signature
        approval
        assigned
        followers
        dueDate
        tags
        color
        termLength
        active
        assignTo
        language
        template
        languagePacks
        passwordProtected
        disabled
      }
      nextToken
    }
  }
`;
export const getUserProfile = /* GraphQL */ `
  query GetUserProfile($pk: ID!) {
    getUserProfile(pk: $pk) {
      pk
      sk
      name
      picture
      email
    }
  }
`;
export const listCurrentUserResources = /* GraphQL */ `
  query ListCurrentUserResources {
    listCurrentUserResources {
      items {
        pk
        sk
        workspace
        owners
        group
        readOnlyGroup
        subscription
        createdAt
        updatedAt
        name
        picture
        email
        plan
        address
        phone
        cin
        country
        locale
        role
        description
        solution
        state
        stateUpdatedAt
        excerpt
        excerptUpdatedAt
        sender
        text
        content
        attachments
        submitLayout
        disabledSubmitLanguages
        reportSettings
        lastReads
        pushSubscription
        signature
        approval
        assigned
        followers
        dueDate
        tags
        color
        termLength
        active
        assignTo
        language
        template
        languagePacks
        passwordProtected
        disabled
      }
      nextToken
    }
  }
`;
export const translateLanguagePack = /* GraphQL */ `
  query TranslateLanguagePack(
    $languagePack: AWSJSON!
    $sourceLanguage: String!
    $targetLanguages: [String]!
  ) {
    translateLanguagePack(
      languagePack: $languagePack
      sourceLanguage: $sourceLanguage
      targetLanguages: $targetLanguages
    )
  }
`;
export const getResource = /* GraphQL */ `
  query GetResource($pk: ID!, $sk: ID!) {
    getResource(pk: $pk, sk: $sk) {
      pk
      sk
      workspace
      owners
      group
      readOnlyGroup
      subscription
      createdAt
      updatedAt
      name
      picture
      email
      plan
      address
      phone
      cin
      country
      locale
      role
      description
      solution
      state
      stateUpdatedAt
      excerpt
      excerptUpdatedAt
      sender
      text
      content
      attachments
      submitLayout
      disabledSubmitLanguages
      reportSettings
      lastReads
      pushSubscription
      signature
      approval
      assigned
      followers
      dueDate
      tags
      color
      termLength
      active
      assignTo
      language
      template
      languagePacks
      passwordProtected
      disabled
    }
  }
`;
export const listResources = /* GraphQL */ `
  query ListResources(
    $pk: ID
    $sk: ModelIDKeyConditionInput
    $filter: ModelResourceFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listResources(
      pk: $pk
      sk: $sk
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        pk
        sk
        workspace
        owners
        group
        readOnlyGroup
        subscription
        createdAt
        updatedAt
        name
        picture
        email
        plan
        address
        phone
        cin
        country
        locale
        role
        description
        solution
        state
        stateUpdatedAt
        excerpt
        excerptUpdatedAt
        sender
        text
        content
        attachments
        submitLayout
        disabledSubmitLanguages
        reportSettings
        lastReads
        pushSubscription
        signature
        approval
        assigned
        followers
        dueDate
        tags
        color
        termLength
        active
        assignTo
        language
        template
        languagePacks
        passwordProtected
        disabled
      }
      nextToken
    }
  }
`;
export const resourcesByWorkspace = /* GraphQL */ `
  query ResourcesByWorkspace(
    $workspace: ID
    $pk: ModelIDKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelResourceFilterInput
    $limit: Int
    $nextToken: String
  ) {
    resourcesByWorkspace(
      workspace: $workspace
      pk: $pk
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        pk
        sk
        workspace
        owners
        group
        readOnlyGroup
        subscription
        createdAt
        updatedAt
        name
        picture
        email
        plan
        address
        phone
        cin
        country
        locale
        role
        description
        solution
        state
        stateUpdatedAt
        excerpt
        excerptUpdatedAt
        sender
        text
        content
        attachments
        submitLayout
        disabledSubmitLanguages
        reportSettings
        lastReads
        pushSubscription
        signature
        approval
        assigned
        followers
        dueDate
        tags
        color
        termLength
        active
        assignTo
        language
        template
        languagePacks
        passwordProtected
        disabled
      }
      nextToken
    }
  }
`;
export const getResourceAccessCredentials = /* GraphQL */ `
  query GetResourceAccessCredentials($resourceKey: ID!) {
    getResourceAccessCredentials(resourceKey: $resourceKey) {
      resourceKey
      accessCode
      group
      createdAt
      updatedAt
    }
  }
`;
export const listResourceAccessCredentialss = /* GraphQL */ `
  query ListResourceAccessCredentialss(
    $resourceKey: ID
    $filter: ModelResourceAccessCredentialsFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listResourceAccessCredentialss(
      resourceKey: $resourceKey
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        resourceKey
        accessCode
        group
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const resourceAccessCredentialsByAccessCode = /* GraphQL */ `
  query ResourceAccessCredentialsByAccessCode(
    $accessCode: ID
    $sortDirection: ModelSortDirection
    $filter: ModelResourceAccessCredentialsFilterInput
    $limit: Int
    $nextToken: String
  ) {
    resourceAccessCredentialsByAccessCode(
      accessCode: $accessCode
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        resourceKey
        accessCode
        group
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getResourceAccessKey = /* GraphQL */ `
  query GetResourceAccessKey($id: ID!) {
    getResourceAccessKey(id: $id) {
      id
      resourceKey
      email
      createdAt
      updatedAt
    }
  }
`;
export const listResourceAccessKeys = /* GraphQL */ `
  query ListResourceAccessKeys(
    $id: ID
    $filter: ModelResourceAccessKeyFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listResourceAccessKeys(
      id: $id
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        id
        resourceKey
        email
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const accessIdsByResourceKey = /* GraphQL */ `
  query AccessIdsByResourceKey(
    $resourceKey: String
    $sortDirection: ModelSortDirection
    $filter: ModelResourceAccessKeyFilterInput
    $limit: Int
    $nextToken: String
  ) {
    accessIdsByResourceKey(
      resourceKey: $resourceKey
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        resourceKey
        email
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getInvitation = /* GraphQL */ `
  query GetInvitation($id: ID!) {
    getInvitation(id: $id) {
      id
      email
      workspace
      role
      workspaceName
      senderName
      locale
      group
      createdAt
      updatedAt
    }
  }
`;
export const listInvitations = /* GraphQL */ `
  query ListInvitations(
    $filter: ModelInvitationFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listInvitations(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        email
        workspace
        role
        workspaceName
        senderName
        locale
        group
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const invitationsByWorkspace = /* GraphQL */ `
  query InvitationsByWorkspace(
    $workspace: ID
    $sortDirection: ModelSortDirection
    $filter: ModelInvitationFilterInput
    $limit: Int
    $nextToken: String
  ) {
    invitationsByWorkspace(
      workspace: $workspace
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        email
        workspace
        role
        workspaceName
        senderName
        locale
        group
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getVoicemail = /* GraphQL */ `
  query GetVoicemail($contactId: ID!) {
    getVoicemail(contactId: $contactId) {
      contactId
      workspaceId
      assetId
      createdAt
      updatedAt
    }
  }
`;
export const listVoicemails = /* GraphQL */ `
  query ListVoicemails(
    $contactId: ID
    $filter: ModelVoicemailFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listVoicemails(
      contactId: $contactId
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        contactId
        workspaceId
        assetId
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
