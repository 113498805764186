import React, {useContext, useEffect, useMemo, useState} from 'react';
import clsx from 'clsx';
import {
    makeStyles,
    Divider,
    Hidden, Paper,
} from '@material-ui/core';
import ReportAttributesPanel from "./ReportAttributesPanel";
import ChatPlaceholder from "./ChatPlaceholder";
import ReportChat from "./ReportChat";
import ReportToolbar from "./ReportToolbar";
import {useDispatch, useSelector} from "react-redux";
import {workspaceReports} from "../../state/reports/selectors";
import {useTranslation} from "react-i18next";
import InfoIcon from "@material-ui/icons/Info";
import GroupIcon from "@material-ui/icons/Group";
import BallotIcon from "@material-ui/icons/Ballot";
import {CardShadow, CardShadow2} from "../../constants";
import {useReport} from "../../hooks/useReport";
import {Navigate, useNavigate, useParams} from "react-router-dom";
import {toId, toReportKey, toWorkspaceKey} from "../../utils/apiUtils";
import ReportList from "./ReportList";
import {isApprover} from "../../utils/user";
import {currentUserRoleSelector} from "../../state/members/selectors";
import PropTypes from "prop-types";

const useStyles = makeStyles((theme) => ({
    reportWithoutChat: {
        display: "flex",
    },
    paper: {
        minWidth: 0,
        [theme.breakpoints.down('sm')]: {
            width: "100%"
        },
        [theme.breakpoints.up('md')]: {
            flex: "1 1 auto",
        },
        display: "flex",
        flexDirection: "column",
        borderRadius: "16px",
        border: `1px solid ${theme.palette.grey[100]}`,
        boxShadow: "rgb(145 158 171 / 20%) 0px 0px 2px 0px, rgb(145 158 171 / 12%) 0px 12px 24px -4px",
        transition: "box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms",
        maxWidth: theme.spacing(90),
        marginTop: theme.spacing(2),
        marginLeft: "auto",
        marginRight: "auto"
    },
    attributePanel: {
        flex: "1 1 auto",
        overflow: "auto"
    },
}));

const ReportWithoutChat = ({className, report}) => {
    const classes = useStyles();

    return (
        <div className={clsx(className, classes.reportWithoutChat)}>
            <Paper className={classes.paper}>
                <ReportToolbar report={report}/>
                <Divider/>
                <ReportAttributesPanel
                    className={clsx(classes.attributePanel)}
                    report={report}
                    readOnly
                />
            </Paper>
        </div>
    );
}

ReportWithoutChat.propTypes = {
    className: PropTypes.string,
    report: PropTypes.object
}

ReportWithoutChat.defaultProps = {
};


export default ReportWithoutChat;