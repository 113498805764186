import React, {useContext, useEffect, useState} from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import {
    makeStyles,
    useTheme,
    Divider,
    Dialog,
    useMediaQuery, DialogTitle, DialogContent, DialogActions, Popper, Fade, Paper
} from '@material-ui/core';
import {useTranslation} from "react-i18next";
import DialogContentText from "@material-ui/core/DialogContentText";
import Button from "@material-ui/core/Button";
import {ApiContext} from "../contexts/ApiContext";
import {NotificationContext} from "../contexts/NotificationContext";
import {useSelector} from "react-redux";
import {currentUserSelector} from "../state/currentUser/selectors";
import {toId} from "../utils/apiUtils";

const useStyles = makeStyles((theme) => ({
    notificationDialog: {
        marginTop: theme.spacing(1),
        zIndex: theme.zIndex.drawer + 3,
    },
    paper: {
        display: "flex",
        flexDirection: "row",
        //maxWidth: theme.breakpoints.values.sm
        maxWidth: 480
    },
    dialogTitle: {
        padding: 0,
        paddingBottom: theme.spacing(3)
    },
    dialogText: {
        marginBottom: 0,
    },
    logo: {
        margin: [theme.spacing(1), 0, theme.spacing(3), theme.spacing(3)]
    },
    dialogActions: {
        padding: [theme.spacing(1), theme.spacing(3), theme.spacing(2), theme.spacing(3)],
        "&>button:not(:first-child)": {
            marginLeft: theme.spacing(2),
        }
    },
    notificationDialogContent: {
        //flex: "1 1 auto",
        //padding: [theme.spacing(2.5), theme.spacing(3), theme.spacing(1), theme.spacing(3)],
        display: "flex"
    },
}));

export const getNotificationsState = (userId) => {
    const value = window.localStorage.getItem(`whispero.${userId}.notifications`);
    return Boolean(value) ? JSON.parse(value) : null;
}

export const setNotificationsState = (userId, value) => {
    window.localStorage.setItem(`whispero.${userId}.notifications`, JSON.stringify(value));
}

const NotificationOptInDialog = ({anchorEl}) => {
    const classes = useStyles();
    const { t } = useTranslation();
    const api = useContext(ApiContext);
    const notificationService = useContext(NotificationContext);
    const currentUser = useSelector(currentUserSelector());
    const currentUserId = toId(currentUser.pk);
    const [open, setOpen] = useState(false);

    const subscribeUser = () => {
        notificationService.subscribe((sub) => {
            if (sub) {
                api.mutation.updateResource(currentUser.pk, currentUser.pk, {pushSubscription: JSON.stringify(sub)});
            }
        });
    }

    useEffect(() => {
        if (!anchorEl) {
            return;
        }

        // Get result of previously displayed notification dialog
        const pushState = getNotificationsState(currentUserId)?.push;

        if (notificationService.isEnabled() && pushState !== 'disabled') {
            notificationService.getSubscription((sub) => {
                const currentUserSubscription = currentUser.pushSubscription ?? {};
                if (sub && sub.endpoint !== currentUserSubscription.endpoint) {
                    // Browser subscription may not match the subscription loaded from the database if a user is
                    // using the application from a browser that was previously used by another user
                    console.log("subscription does not match");
                    sub.unsubscribe();
                }

                // If user has already previously accepted push notifications
                if (pushState === "enabled") {
                    console.log("push already enabled", sub);
                    // Subscription may be null if a user is using the application from a new browser
                    subscribeUser();

                } else {
                    console.log("opening notification dialog")
                    // Display notification dialog
                    setOpen(true);
                }
            });

        }
    }, [anchorEl])

    const handleAcceptClick = () => {
        setOpen(false);
        setNotificationsState(currentUserId, {push: 'enabled'});
        subscribeUser();
    }

    const handleRejectClick = () => {
        setOpen(false);
        setNotificationsState(currentUserId, {result: 'reject'});
    }

    return (
        <Popper
            className={classes.notificationDialog}
            open={open}
            anchorEl={anchorEl}
            transition
            aria-labelledby="notification-dialog-title"
            aria-describedby="notification-dialog-description"
        >
            {({ TransitionProps }) => (
                <Fade {...TransitionProps} timeout={350}>
                    <Paper className={classes.paper} elevation={8}>
                        <img src="/static/images/logo_transparent.png" height="70" className={classes.logo}/>
                        <div>
                            <DialogTitle id="notification-dialog-title">
                                {t("notificationDialogTitle")}
                            </DialogTitle>
                            <DialogContent>
                                <DialogContentText id="notification-dialog-description" classes={{root: classes.dialogText}}>
                                    {t("notificationDialogText")}
                                </DialogContentText>
                            </DialogContent>
                            <DialogActions className={classes.dialogActions}>
                                <Button onClick={handleRejectClick} color="primary">
                                    {t("noThanks")}
                                </Button>
                                <Button onClick={handleAcceptClick} color="primary" variant="contained" autoFocus>
                                    {t("enable")}
                                </Button>
                            </DialogActions>
                        </div>
                    </Paper>
                </Fade>
            )}
        </Popper>
    )
}

NotificationOptInDialog.propTypes = {
    anchorEl: PropTypes.object
}

NotificationOptInDialog.defaultProps = {
};

export default NotificationOptInDialog;