import React, {useContext, useState} from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import {
    Avatar,
    Box,
    Button,
    Card,
    CardActions,
    CardContent,
    Divider,
    Typography,
    makeStyles, CardHeader, Grid, TextField, CardActionArea, Container
} from '@material-ui/core';
import RichTextEditor from "./RichTextEditor";
import dialogEn from "../../locales/en/dialogs.json";

const useStyles = makeStyles((theme) => ({
    dialogContainer: {
        padding: theme.spacing(10),
    },
    editor: {
        backgroundColor: "white"
    }
}));

const RteContainer = ({ className }) => {
    const classes = useStyles();

    //const data = dialogEn.disclaimer.defaultText;
    //const data = dialogEn.submit.defaultText;
    const data = dialogEn.confirmation.defaultText;

    return (
        <Container maxWidth="sm" className={clsx(classes.dialogContainer, className)}>
            <RichTextEditor className={classes.editor} value={data}/>
        </Container>
    );
};

RteContainer.propTypes = {
};

RteContainer.defaultProps = {
};

export default RteContainer;
