import React, {useContext, useEffect, useRef, useState} from 'react';
import {
    alpha,
    ButtonBase, ClickAwayListener,
    Divider, IconButton, InputAdornment, ListItemIcon, ListItemText,
    makeStyles, TextField, Tooltip, Typography, useTheme
} from '@material-ui/core';
import {useTranslation} from "react-i18next";
import TileButton from "../TileButton";
import AddCircleOutlinedIcon from "@material-ui/icons/AddCircleOutlined";
import PropTypes from "prop-types";

const useStyles = makeStyles((theme) => ({
    addTileButtonContent: {
        border: `1px dashed ${theme.palette.grey[400]}`,
    },
}));

const AddTileButton = (props) => {
    const classes = useStyles();
    const { t } = useTranslation();

    return (
        <TileButton defaultLabel={t("addNew")} classes={{content: classes.addTileButtonContent}} showContextMenu={false} {...props}>
            <AddCircleOutlinedIcon fontSize="large" color="primary"/>
        </TileButton>
    );
};

AddTileButton.propTypes = {
    ...TileButton.propTypes
};

AddTileButton.defaultProps = {
};

export default AddTileButton;